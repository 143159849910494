import 'prismjs/themes/prism.css';

import React from 'react';
import Prism from 'prismjs';

// The jsx uses Prisum directly, gatsby-remark-prismjs adds some language support,
// e.g. 'java' works in .md pages but not here in the jsx
// so we have some aliases to map java => javascript etc...
const aliasList = {
  clike: 'clike',
  css: 'css',
  scss: 'css',
  sass: 'css',
  md: 'markup',
  markdown: 'markup',
  bash: 'markup',
  text: 'markup',
  markup: 'markup',
  xml: 'markup',
  html: 'markup',
  mathml: 'markup',
  svg: 'markup',
  java: 'javascript',
  javascript: 'javascript',
  js: 'javascript',
};
const CodeHighlight = class extends React.Component {
  render() {
    const defaultLang = `markup`;
    const disableFormatting = this.props.disableFormatting;
    const dataLabel = this.props.dataLabel || '';
    const lang = aliasList[this.props.lang] || defaultLang;
    const lineNumberHTML = this.props.lineNumberHTML || '';
    const raw = this.props.raw || false;
    let code = this.props.code;
    let html = ``;
    let lineNumberClass = ``;

    if (lineNumberHTML) {
      lineNumberClass = `line-numbers`;
    }
    if (disableFormatting) {
      html = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    } else if (raw) {
      html = code;
    } else {
      html = Prism.highlight(code, Prism.languages[lang], lang);
    }

    return (
      <div className="gatsby-highlight">
        <pre
          className={`language-${lang} ${lineNumberClass}`}
          data-label={dataLabel}
        >
          <code
            className={`language-${lang}`}
            dangerouslySetInnerHTML={{ __html: html }}
          ></code>
          <span dangerouslySetInnerHTML={{ __html: lineNumberHTML }}></span>
        </pre>
      </div>
    );
  }
};

export default CodeHighlight;
