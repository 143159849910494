import 'prismjs/themes/prism.css';
import React from 'react';
import Layout from '../components/Layout';
import CodeHighlight from '../components/CodeHighlight';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { withPrefix } from 'gatsby';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

/* eslint-disable */

const ContentDemoPage = () => (
  <Layout pageType="DetailsPage">
    <GatsbySeo noindex={true} />
    <div className="main blog-post-page container-narrow blog-page">
      <section className="hero">
        <div className="image-wrapper">
          <PreviewCompatibleImage
            imageInfo={{
              image: `${withPrefix('/')}img/hero-grid-yellow.png`,
              alt: "a grid fo plus icons"
            }}
          />
        </div>
      </section>
      <section>
        <div className="container content">
          <div className="grid-container flex-start">
            <div className="col-xs-12 off-md-1 col-md-10">
              {/*
                                 gatsby outputs some style attributes which cause issues in
                                 the jsx so moved here to this style tag
                            */}
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.gatsby-resp-image-image{opacity: 1; transition: opacity 0.5s ease 0s; color: inherit; box-shadow: white 0px 0px 0px 400px inset;}.gatsby-resp-image-background-image{padding-bottom: 50.1667%; position: relative; bottom: 0px; left: 0px; background-size: cover; display: block; transition: opacity 0.5s ease 0.5s; opacity: 0;}.gatsby-resp-image-link{display: block}.gatsby-resp-image-wrapper{position: relative; display: block; margin-left: auto; margin-right: auto;  max-width: 600px;}[data-label]:before{content:attr(data-label);float:right;opacity:.5}.gatsby-resp-iframe-wrapper{padding-bottom: 56.25%; position: relative; height: 0; overflow: hidden;}iframe{position: absolute; top: 0; left: 0; width: 100%; height: 100%;}',
                }}
              ></style>
              <h1>[h1] heading 1</h1>
              <h2>[h2] heading 2</h2>
              <h3>[h3] heading 3</h3>
              <h4>[h4] heading 4</h4>
              <h5>[h5] heading 5</h5>
              <h6>[h6] heading 6</h6>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`# [h1] heading 1
## [h2] heading 2
### [h3] heading 3
#### [h4] heading 4
##### [h5] heading 5
###### [h6] heading 6`}
                disableFormatting={true}
              />
              <p>
                <small>
                  NB: please don't use H1 in content, there should only be one
                  H1 on a page and it's usually in the template
                </small>
              </p>

              <p>[p] Normal paragraph text...</p>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`[p] Normal paragraph text...`}
                disableFormatting={true}
              />

              <p>Text formatting, bold, italic, linebreak etc...</p>
              <p>
                At vero eos et <em>[em] accusamus et</em> iusto odio{' '}
                <strong>[strong] dignissimos ducimus</strong> qui{' '}
                <strong>
                  <em>[em + strong] blanditiis praesentium</em>
                </strong>{' '}
                vero eos et accusamus et&nbsp;
                <code className={`language-text`}>
                  [code] voluptatum deleniti
                </code>{' '}
                atque{' '}
                <a href="https://www.gatsbyjs.org/docs/mdx/markdown-syntax/">
                  link; corrupti quos
                </a>{' '}
                dolores <del>[del] iusto odio</del> eos dignissimos. To add a
                linebreak put two spaces
                <br /> at the end of the line
                <br />
                Read more{' '}
                <a href={`https://www.gatsbyjs.org/docs/mdx/markdown-syntax/`}>
                  gatsby md syntax
                </a>
              </p>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Text formatting, bold, italic, linebreak etc...

At vero eos et *[em] accusamus et* iusto odio **[strong] dignissimos ducimus** qui
***[em + strong] blanditiis praesentium*** vero eos et \`[code] voluptatum deleniti\`
atque [link: corrupti quos](https://www.gatsbyjs.org/docs/mdx/markdown-syntax/)
dolores ~~[del] iusto odio~~ eos dignissimos.
To add a linebreak put two spaces
at the end of the line
Read more [gatsby md syntax](https://www.gatsbyjs.org/docs/mdx/markdown-syntax/).`}
                disableFormatting={true}
              />

              <p>Ordered list...</p>
              <ol>
                <li>[ol li] One</li>
                <li>[ol li] Two</li>
                <li>[ol li] Three</li>
              </ol>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Ordered list...

1. [ol li] One
1. [ol li] Two
1. [ol li] Three`}
                disableFormatting={true}
              />

              <p>Unordered list...</p>
              <ul>
                <li>[ul li] Gatsby</li>
                <li>[ul li] Gatsby</li>
                <li>[ul li] Gatsby</li>
              </ul>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Unordered list...

* [ul li] Gatsby
* [ul li] Gatsby
* [ul li] Gatsby`}
                disableFormatting={true}
              />

              <p>Blockquote</p>
              <blockquote className="quote">
                <p>
                  [blockquote] id est laborum et dolorum fuga. Et harum quidem
                  rerum facilis
                </p>
              </blockquote>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Blockquote

> [blockquote] id est laborum et dolorum fuga. Et harum quidem rerum facilis`}
                disableFormatting={true}
              />

              <p>Demo section</p>
              <div className="demo">Some HTML here</div>
              <div className="demo" data-title="Some bespoke title here...">
                Some HTML here
              </div>
              <div className="demo" data-title="">
                Some HTML here, with no title showing
              </div>
              <div className="demo attention" data-title="">
                Some HTML here, with no title showing and a post-it
                background-color
              </div>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`<div class="demo">
  Some HTML here
</div>
<div class="demo" data-title="Some bespoke title here...">
  Some HTML here
</div>
<div class="demo" data-title="">
  Some HTML here, with no title showing
</div>
<div class="demo attention" data-title="">
  Some HTML here, with no title showing and a post-it background-color
</div>`}
                disableFormatting={true}
              />

              <p>Wide section (breakout to width of Hero Banner)</p>

              <div className="container-wide">
                <p>
                  "Anselmo," the old man said. "I am called Anselmo and I come
                  from Barco deAvila. Let me help you with that pack." <br />
                  The young man, who was tall and thin, with sun-streaked fair
                  hair, and a wind- and sun-burned face, who wore the sun-faded
                  Bannel shirt, a pair of peasant's trousers and rope-soled
                  shoes, leaned over, put his arm through one of the leather
                  pack straps and swung the heavy pack up onto his shoulders. He
                  worked his arm through the other strap and settled the weight
                  of the pack against his back. <br />
                  His shirt was still wet from where the pack had rested. <br />
                  "I have it up now," he said. "H ow do we go?" "We climb,"
                  Anselmo said.
                </p>
              </div>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Wide section, Hero width

<div class="container-wide">

// markdown here

</div>`}
                disableFormatting={true}
              />

              <p>Super wide section (breakout to width of Nav-bar)</p>

              <div className="container-super-wide">
                <hr />
                <table className="data-table eight-col-even">
                  <caption>Table caption</caption>
                  <tbody>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Column 1</th>
                      <th>Column 2</th>
                      <th>Column 3</th>
                      <th>Column 4</th>
                      <th>Column 5</th>
                      <th>Column 6</th>
                      <th>Column 7</th>
                      <th>Column 8</th>
                    </tr>
                    <tr>
                      <th scope="row">Row 1</th>
                      <td>Foo Bar</td>
                      <td>Foo Bar</td>
                      <td colspan="2">Foo Bar (column span 2)</td>
                      <td>Foo Bar</td>
                      <td>Foo Bar</td>
                      <td>Foo Bar</td>
                      <td>Foo Bar</td>
                    </tr>
                    <tr>
                      <th scope="row">Row 2</th>
                      <td>Baz Qux</td>
                      <td>Baz Qux</td>
                      <td>Baz Qux</td>
                      <td>Baz Qux</td>
                      <td colspan="2">Baz Qux (column span 2)</td>
                      <td>Baz Qux</td>
                      <td>Baz Qux</td>
                    </tr>
                  </tbody>
                </table>
                <table className="data-table two-col-even"></table>
                <table className="data-table three-col-even"></table>
                <table className="data-table four-col-even"></table>
                <table className="data-table five-col-even"></table>
                <table className="data-table six-col-even"></table>
                <table className="data-table seven-col-even two-col-hilite-odd"></table>
                <table className="data-table eight-col-even two-col-hilite-even"></table>
                <hr />
              </div>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Super wide section, Nav-bar width

<div class="container-super-wide">

// markdown here

</div>`}
                disableFormatting={true}
              />

              <p>Image</p>

              <p>
                <span className="gatsby-resp-image-wrapper">
                  <a
                    className="gatsby-resp-image-link"
                    href="/img/iStock_boats.jpg"
                    target="_blank"
                    rel="noopener"
                  >
                    <span className="gatsby-resp-image-background-image hover-highlight-image hover-brighten-image hover-saturate-image hover-grayscale-image"></span>
                    <img
                      className="gatsby-resp-image-image"
                      alt="The alt text"
                      title="The alt text"
                      src="/img/iStock_boats.jpg"
                      srcSet="/img/iStock_boats.jpg 512w, /img/iStock_boats.jpg 600w"
                      sizes="(max-width: 600px) 100vw, 600px"
                      loading="lazy"
                    />
                  </a>
                </span>
              </p>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Image

![The alt text](./iStock_boats.jpg)`}
                disableFormatting={true}
              />

              <p>Hardrule</p>
              <hr />
              <p></p>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Hardrule
___`}
                disableFormatting={true}
              />

              <p>Screen-reader only text</p>
              <h3>
                Ambiguous Heading{' '}
                <span className={`sr-only`}>clarification</span>
              </h3>
              <p>
                (You will need this to add context, if you have multiple
                headings on a page with the same text)
              </p>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`### Ambiguous Heading <span class="sr-only">clarification</span>`}
                disableFormatting={true}
              />

              <p>Code blocks </p>
              <p>
                <small>
                  (common supported langs: clike, java, javascript; css, scss,
                  sass; html, mathml, markup, svg, xml; bash, md, text)
                </small>
              </p>

              <CodeHighlight lang={`clike`} code={sampleCodeClike} />
              <CodeHighlight
                dataLabel={`markdown`}
                code={`\`\`\`clike
${sampleCodeClike}
\`\`\``}
                disableFormatting={true}
              />
              <CodeHighlight
                lang={`java`}
                code={sampleCodeJAVA}
                lineNumberHTML={`<span style="white-space: normal; width: auto; left: 0;" aria-hidden="true" class="line-numbers-rows"><span></span><span></span><span></span><span></span><span></span><span></span></span>`}
              />
              <CodeHighlight
                dataLabel={`markdown`}
                code={`\`\`\`java{numberLines: true}
${sampleCodeJAVA}
\`\`\``}
                disableFormatting={true}
              />

              <CodeHighlight lang={`css`} code={sampleCodeCSS} />
              <CodeHighlight
                dataLabel={`markdown`}
                lang={`markdown`}
                code={`\`\`\`css
${sampleCodeCSS}
\`\`\``}
                disableFormatting={true}
              />
              <CodeHighlight lang={`md`} code={sampleCodeMarkup} />
              <CodeHighlight
                dataLabel={`markdown`}
                lang={`markdown`}
                code={`\`\`\`markup
${sampleCodeMarkup}
\`\`\``}
              />
              <CodeHighlight
                raw={true}
                lang={`markdown`}
                code={sampleCodeTextFake}
              />
              <CodeHighlight
                dataLabel={`markdown`}
                lang={`markdown`}
                code={`\`\`\`md
${sampleCodeText}
\`\`\``}
                disableFormatting={true}
              />

              <p>Code callouts (similar to AsciiDoc) are supported in code blocks.</p>
              <p>
                <small>
                  For the marker to appear in the code block it needs to be put inside a comment (so that PrsimJS marks it as such).
                  Usual <code class="language-text">// ...</code>, <code class="language-text">/* ... */</code>,
                  <code class="language-text"># ...</code> or <code class="language-text">&lt;--! ... --&gt;</code>
                  should suffice (depending on the language of the block).
                </small>
              </p>
              <p>
                <small>
                  Note the newlines around the fenced div markers (<code class="language-text">:::</code>)
                  and a 4 space indent on the sublist.
                  These are necessary, otherwise <code class="language-text">yarn format-md</code> mangles
                  the markdown code.
                </small>
              </p>
              <div class="gatsby-highlight" data-language="yaml"><pre class="language-yaml"><code class="language-yaml"><span class="token key atrule">user</span><span class="token punctuation">:</span><br />
                <span class="token key atrule">  username</span><span class="token punctuation">:</span> user <i class="callout" data-value="1"></i><b>(1)</b><br />
                <span class="token key atrule">  password</span><span class="token punctuation">:</span> p@SSWoRd <i class="callout" data-value="2"></i><b>(2)</b></code></pre>
              </div>
              <div class="callouts">
                <ol>
                  <li>Enter username here.</li>
                  <li>
                    <p>Enter password here.</p>
                    <ul>
                      <li>at least 6 characters</li>
                      <li>mix uppercase and lowercase characters</li>
                    </ul>
                  </li>
                </ol>
              </div>
              <CodeHighlight
                dataLabel={`markdown`}
                lang={`markdown`}
                code={`\`\`\`yaml
user:
  username: user # (callout-1)
  password: p@SSWoRd # (callout-2)
\`\`\`

:::::: {.callouts}

1. Enter username here.
2. Enter password here.
    * Not shorter than 6 characters.
    * Uppercase and lowercase letters

::::::`}
                disableFormatting={true}
              />

              <p>Embed youtube (HTML is supported in .md files)</p>
              <p>
                <small>
                  Please note; Gatsby may rewrite some HTML to make it
                  responsive e.g. iframe
                </small>
              </p>
              <div className="gatsby-resp-iframe-wrapper">
                <iframe
                  src="https://www.youtube.com/embed/XD3DAE_eWJ8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen=""
                  title={'Example youtube iframe'}
                ></iframe>
              </div>

              <CodeHighlight
                dataLabel="markdown"
                code={`Embed youtube (HTML is supported in .md files)

<small>Please note; Gatsby may rewrite some HTML to make it responsive</small>

<iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/XD3DAE_eWJ8"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                disableFormatting={true}
              />

              <p></p>
              <p>Table (data-table)</p>
              <table className="data-table">
                <tbody>
                  <tr>
                    <th>Column 1</th>
                    <th>Column 2</th>
                  </tr>
                  <tr>
                    <td>
                      <strong>Row 1, cell 1</strong> <br /> Without paragraph
                      tags.
                    </td>
                    <td>
                      <strong>Row 1, cell 2</strong> <br /> Ut enim ad minim
                      veniam, quis nostrud exercitation et dolore magna aliqua.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        [p] <strong>Row 2, cell 1</strong> <br /> With paragraph
                        tags{' '}
                      </p>
                    </td>
                    <td>
                      <p>
                        [p] <strong>Row 2, cell 2</strong> <br /> Ut enim ad
                        minim veniam, quis nostrud exercitation et dolore magna
                        aliqua.{' '}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <CodeHighlight
                dataLabel={`markdown`}
                code={`Tables (data-table)

<table class="data-table">
<tr>
<th>Column 1</th>
<th>Column 2</th>
</tr>
<tr>
<td>
**Row 1, cell 1**
Without paragraph tags
</td>
<td>
**Row 1, cell 2**
Ut enim ad minim veniam, quis nostrud exercitation et dolore magna aliqua.
</td>
</tr>
<tr>
<td>

[p] **Row 2, cell 1**
With paragraph tags

</td>
<td>

[p] **Row 2, cell 2**
Ut enim ad minim veniam, quis nostrud exercitation et dolore magna aliqua.

</td>
</tr>
</table>`}
                disableFormatting={true}
              />

              <p>Table (linearise-data-table)</p>

              <table className="linearise-data-table-tablet data-table first-col-slim">
                <thead>
                  <tr>
                    <th>Heading - one</th>
                    <th>Heading - two</th>
                    <th>Heading - three</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-title="Heading - one">
                      <p>This table will linearise on tablet and below</p>
                    </td>
                    <td data-title="Heading - two">
                      <CodeHighlight lang={`markup`} code={sampleCodeXML} />
                    </td>
                    <td data-title="Heading - three">
                      <p>
                        Sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <CodeHighlight
                dataLabel={`markdown`}
                code={`Table (linearise-data-table)

<table class="linearise-data-table-tablet data-table first-col-slim">
<thead>
<tr>
<th>Heading - one</th>
<th>Heading - two</th>
<th>Heading - three</th>
</tr>
</thead>
<tr>
<td data-title="Heading - one">

This table will linearise on tablet and below

</td>
<td data-title="Heading - two">

\`\`\`xml
${sampleCodeXML}
\`\`\`
</td>
<td data-title="Heading - three">

Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
Ut enim ad minim veniam, quis nostrud exercitation ullamco

</td>
</tr>
</table>`}
                disableFormatting={true}
              />

              <p>Table (compact data table examples)</p>

              <p>
                <small>
                  Headers can be rotated 60 or 90 degrees (th.rotate60 or
                  th.rotate90)
                </small>
              </p>

              <table className="linearise-data-table-tablet data-table data-table--compact code-auto">
                <thead>
                  <tr>
                    <th className="va-b" colSpan="2">
                      HTML example
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>JAWS, Chrome</span>
                      </div>
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>JAWS, Edge</span>
                      </div>
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>NVDA, Firefox</span>
                      </div>
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>NVDA, Edge</span>
                      </div>
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>Narrator, Firefox</span>
                      </div>
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>Narrator, Edge</span>
                      </div>
                    </th>
                    <th className="rotate60">
                      <div>
                        <span>VoiceOver</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td data-title="HTML example" className="code" rowSpan="3">
                    <CodeHighlight
                      lang={`markup`}
                      code={`<a href="#">
  Document
  <svg>
   <title>download</title>
   <desc>download</desc>
   <text>download</text>
   ...
  </svg>
</a>`}
                    />
                  </td>
                  <th scope="row" className="ta-r va-b bt-s">
                    Title
                  </th>
                  <td
                    data-title="JAWS, Chrome"
                    className="warning ta-c-tablet pr-nil"
                    tabIndex="0"
                  >
                    <span className="message">Warning</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Fail</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="bt-s ta-r va-b">
                    Description
                  </th>
                  <td
                    data-title="JAWS, Chrome"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="bt-s ta-r va-b">
                    Text
                  </th>
                  <td
                    data-title="JAWS, Chrome"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabindex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                </tr>
              </table>

              <table className="linearise-data-table-tablet data-table data-table--compact two-col-hilite-even">
                <caption>
                  Table with caption example and alternate column
                  background-color for readability
                </caption>
                <thead>
                  <tr>
                    <th colSpan="7" className="ta-c-tablet br-tablet bl-tablet">
                      Group 1
                    </th>
                    <th colSpan="7" className="ta-c-tablet br-tablet bl-tablet">
                      Group 2
                    </th>
                  </tr>
                  <tr>
                    <th className="rotate90 bl-tablet">
                      <div>
                        <span>JAWS, Chrome</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>JAWS, Edge</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>NVDA, Firefox</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>NVDA, Edge</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>Narrator, Firefox</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>Narrator, Edge</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>VoiceOver</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>JAWS, Chrome</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>JAWS, Edge</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>NVDA, Firefox</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>NVDA, Edge</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>Narrator, Firefox</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>Narrator, Edge</span>
                      </div>
                    </th>
                    <th className="rotate90">
                      <div>
                        <span>VoiceOver</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td
                    data-title="JAWS, Chrome"
                    className="warning ta-c-tablet pr-nil"
                    tabIndex="0"
                  >
                    <span className="message">Warning</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Fail</span>
                  </td>
                  <td
                    data-title="JAWS, Chrome"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Fail</span>
                  </td>
                </tr>
                <tr>
                  <td
                    data-title="JAWS, Chrome"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Chrome"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Fail</span>
                  </td>
                </tr>
                <tr>
                  <td
                    data-title="JAWS, Chrome"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Chrome"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="JAWS, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="NVDA, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="NVDA, Edge"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Firefox"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Detail about failure</span>
                  </td>
                  <td
                    data-title="Narrator, Edge"
                    className="pass ta-c-tablet pr-nil"
                  >
                    <span className="message">As expected</span>
                  </td>
                  <td
                    data-title="VoiceOver"
                    tabIndex="0"
                    className="fail ta-c-tablet pr-nil"
                  >
                    <span className="message">Fail</span>
                  </td>
                </tr>
              </table>

              <span
                className={
                  'three-col-even four-col-even five-col-even six-col-even seven-col-even eight-col-even data-table--compact code code-auto td-p-nil'
                }
              ></span>

              <CodeHighlight
                dataLabel={`markdown`}
                code={`Table (two-col-even)
<table class="linearise-data-table-tablet data-table data-table--compact code-auto">
<thead>
<tr>
<th class="va-b" colSpan="2">HTML example</th>
<th class="rotate60"><div><span>JAWS, Chrome</span></div></th>
<th class="rotate60"><div><span>JAWS, Edge</span></div></th>
... more headers
</tr>
</thead>
<tr>
<td data-title="HTML example" class="code" rowSpan="3">

// markdown here

</td>
<th scope="row" class="ta-r va-b bt-s">Title</th>
<td data-title="JAWS, Chrome" class="warning ta-c-tablet pr-nil"><span class="message">Warning</span>
</td>
<td data-title="JAWS, Edge" class="pass ta-c-tablet pr-nil"><span class="message">As expected</span></td>
<td data-title="NVDA, Firefox" tabindex="0" class="fail ta-c-tablet pr-nil"><span class="message">Detail about failure</span></td>
... more data cells
</tr>
... more rows
</table>

<table className="linearise-data-table-tablet data-table data-table--compact two-col-hilite-odd">
<caption>Table with caption example and alternate column background-color for readability</caption>
<thead>
<tr>
<th colSpan="7" className="ta-c-tablet br-tablet bl-tablet">Group 1</th>
<th colSpan="7" className="ta-c-tablet br-tablet">Group 2</th>
</tr>
</thead>
<tr>
<th className="rotate90 bl-tablet">
<div><span>JAWS, Chrome</span></div>
</th>
<th className="rotate90">
<div><span>JAWS, Edge</span></div>
</th>
... more table headers
</tr>
... more rows
</table>`}
                disableFormatting={true}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

const sampleCodeCSS = `/* css, sass, scss */
.example:[before] {
    content: "Hello World!";
}`;

const sampleCodeText = `_md, markdown, bash, text_
## Hello World!
md, bash and text are supported in content markdown pages
for {numberLines: true} and other formatting options see...
https://www.gatsbyjs.org/packages/gatsby-remark-prismjs`;

const sampleCodeTextFake = `<span class="token italic"><span class="token punctuation">_</span><span class="token content">md, markdown, bash, text</span><span class="token punctuation">_</span></span>
<span class="token title important"><span class="token punctuation">##</span> Hello World!</span>
md, bash and text are supported in content markdown pages
for {numberLines: true} and other formatting options see...
https://www.gatsbyjs.org/packages/gatsby-remark-prismjs`;

const sampleCodeJAVA = `// java, javascript
public class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, World");
    }
}`;

const sampleCodeMarkup = `<!-- markup, html, mathml, svg, xml -->
<html lang="en-gb">
    <title>Hello World!</title>
</html>`;

const sampleCodeClike = `// clike
#include <stdio.h>
int main()
{
   printf("Hello, World!");
   return 0;
}`;

const sampleCodeXML = `<root>
    <node data-foo="bar"> Baz qux </node>
</root>`;

export default ContentDemoPage;

/* eslint-enable */
